import React, {useState, useEffect} from 'react';
import {Translate} from '../../components/translations';
import {TrackRegistration} from '../../helpers/trackingEvents';
import {
  UserRegistrationFormState,
  UserRegistrationSecondaryState,
  UserRegistrationData,
  GatsbyPageProps,
} from '../../types';
import {
  setUserToken,
  setUserHash,
  setNewUser,
  setRefreshToken,
  setDeviceKey,
  getRawCookie,
  setRawCookie,
} from '../../helpers';
import {useMutation} from '@apollo/react-hooks';
import {USER_REGISTRATION} from '../../graphql/mutations';
import googleIcon from '../../../static/google-icon.svg';
import Message from '../messages';
import Checkbox from '../customCheckbox';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {VALID_PASSWORD_RE} from '../../helpers/constants';
import {navigate} from 'gatsby';

import './styles.scss';
import ChangeToFactor from '../changeToFactor';
import {eyeIcon} from '../../images/svgIcons';

export const getPackageInfo = () => {
  return !!localStorage.getItem('checkout-data');
};
const Registration = ({
  setShowLogin,
  setShowRegistration,
  promoCode,
  location,
  setHandleCloseRegModal,
}: GatsbyPageProps): JSX.Element => {
  const [registrationMutation, {loading}] = useMutation<UserRegistrationData>(USER_REGISTRATION);

  const [formData, setFormData] = useState<UserRegistrationFormState>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    promoCode: promoCode || '',
    referralCode: '',
  });

  const [passwordMessage, setPasswordMessage] = useState(false);
  const [showTwoFactor, setShowTwoFactor] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [referralCode, setReferral] = useState('');
  const [limitExceed, setLimitExceed] = useState(false);

  useEffect(() => {
    if (location) {
      if (location.state && location.state.email) {
        setFormData({...formData, ['email']: location.state.email});
      }
    }
    const urlParams = new URLSearchParams(window.location.search);
    let referral = urlParams.get('referralCode');
    if (referral) {
      if (referral[referral.length - 1] === '/') {
        referral = referral.slice(0, -1);
        setFormData({...formData, referralCode: referral});
        setReferral(referral);
        setRawCookie('referralCode', referral);
      }
    }
  }, [location]);

  useEffect(() => {
    const ref = getRawCookie('referralCode');
    ref && setReferral(ref);
  }, []);

  useEffect(() => {
    if (showTwoFactor) {
      const el = document.getElementsByClassName('modal-body');
      el && el[0] && el[0].classList.add('modal-body-two-fa');
    } else {
      const el = document.getElementsByClassName('modal-body-two-fa');
      el && el[0] && el[0].classList.remove('modal-body-two-fa');
    }
  }, [showTwoFactor]);

  const [secondaryData, setSecondaryData] = useState<UserRegistrationSecondaryState>({
    checked: false,
    error: false,
    errorMessage: '',
    isModalOpen: false,
    sessionToken: '',
  });

  const redirects = () => {
    if (getPackageInfo()) {
      navigate('/pricing/checkout');
    } else if (location?.state?.navigatePath) {
      navigate(location.state.navigatePath);
    } else {
      if (window.location.host.includes('localhost')) {
        window.location.href = 'http://localhost:3000';
      } else if (window.location.host.includes('37.186.119.181')) {
        window.location.href = 'http://37.186.119.181:3279';
      } else {
        window.location.href = 'https://dash.hexomatic.com';
      }
    }
  };

  const fieldChangeHandler = (event: React.SyntheticEvent): void => {
    const target = event.target as HTMLInputElement;
    const {value, name} = target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const checkHandler = (): void =>
    setSecondaryData({
      ...secondaryData,
      checked: !secondaryData.checked,
    });

  const submitHandler = async () => {
    if (formData.password.length > 64) {
      setLimitExceed(true);
      setPasswordMessage(false);
      return;
    }
    if (VALID_PASSWORD_RE.test(formData.password)) {
      setPasswordMessage(false);
      setLimitExceed(false);
    } else {
      setPasswordMessage(true);
      setLimitExceed(false);
      return;
    }

    setSecondaryData({...secondaryData, error: false, errorMessage: ''});
    if (secondaryData.checked) {
      TrackRegistration(formData);
      const {data} = await registrationMutation({
        variables: {
          ...formData,
          sessionToken: secondaryData.sessionToken,
        },
      });

      if (data) {
        const {
          UserOps: {
            registration: {error, message, token, hash, device_key, refresh_token},
          },
        } = data;
        if (error) {
          setSecondaryData({
            ...secondaryData,
            error: true,
            errorMessage: message,
          });
        } else {
          setUserToken(token);
          setUserHash(hash);
          setRefreshToken(refresh_token);
          setDeviceKey(device_key);
          setNewUser(true);
          redirects();
          // if (Math.round(Math.random())) {
          //   setHandleCloseRegModal && setHandleCloseRegModal(true);
          //   setShowTwoFactor(true);
          // } else {
          //   redirects();
          // }
        }
      }
    } else {
      setSecondaryData({
        ...secondaryData,
        error: true,
        errorMessage: 'Please agree to the Terms of Service',
      });
    }
  };

  const toLogin = () => {
    //setShowLogin && setShowLogin(true);
    //setShowRegistration && setShowRegistration(false);
    navigate('/login');
  };

  return (
    <div className="registration-block">
      {showTwoFactor ? (
        <ChangeToFactor />
      ) : (
        <>
          <h4 className="title">Create your account</h4>
          <button
            className="gmail-btn"
            onClick={() => {
              if (window.location.host.includes('37.186.119.181')) {
                window.location.href = `http://37.186.119.181:4000/api/app/social-callback/hexomatic/google?referralCode=${referralCode}${
                  getPackageInfo() ? '&redirect=pricing/checkout' : ''
                }&deviceKey=${getRawCookie('device_key') || ''}`;
              } else if (window.location.host.includes('localhost')) {
                window.location.href = `http://37.186.119.181:4000/api/app/social-callback/hexomatic/google?referralCode=${referralCode}${
                  getPackageInfo()
                    ? '&redirect=pricing/checkout'
                    : location?.state?.navigatePath
                    ? `&redirect=${location.state.navigatePath.slice(1)}`
                    : ''
                }&deviceKey=${getRawCookie('device_key') || ''}`;
              } else {
                window.location.href = `https://api.hexomatic.com/v2/app/social-callback/hexomatic/google?referralCode=${referralCode}${
                  getPackageInfo() ? '&redirect=pricing/checkout' : ''
                }&deviceKey=${getRawCookie('device_key') || ''}`;
              }
            }}
          >
            <img src={googleIcon} alt="Login with Google" width={30} /> <span>Sign Up with Google</span>
          </button>
          <div className="or-div">
            <div>
              <Translate name="or" />
            </div>
            <hr />
          </div>
          <div className="forms">
            <div className="wrapper">
              <div className="form-item">
                <label>First Name</label>
                <input
                  style={
                    secondaryData.error && secondaryData.errorMessage === 'INVALID_FIRSTNAME'
                      ? {
                          border: '1px',
                          borderColor: '#FB6F6F',
                          borderStyle: 'solid',
                        }
                      : {}
                  }
                  required
                  placeholder="First Name"
                  type="text"
                  name="firstName"
                  onChange={fieldChangeHandler}
                  value={formData.firstName}
                  maxLength={255}
                />
              </div>
              <div className="form-item">
                <label>Last Name</label>
                <input
                  style={
                    secondaryData.error && secondaryData.errorMessage === 'INVALID_LASTNAME'
                      ? {
                          border: '1px',
                          borderColor: '#FB6F6F',
                          borderStyle: 'solid',
                        }
                      : {}
                  }
                  required
                  placeholder="Last Name"
                  type="text"
                  name="lastName"
                  onChange={fieldChangeHandler}
                  value={formData.lastName}
                  maxLength={255}
                />
              </div>
            </div>
            <div className="form-item">
              <label>Email</label>
              <input
                style={
                  secondaryData.error &&
                  (secondaryData.errorMessage === 'INVALID_EMAIL' ||
                    secondaryData.errorMessage === 'USER_ALREADY_EXISTS')
                    ? {border: '1px', borderColor: 'red', borderStyle: 'solid'}
                    : {}
                }
                required
                placeholder="Email"
                type="email"
                name="email"
                onChange={fieldChangeHandler}
                value={formData.email}
                maxLength={320}
              />
            </div>
            <div className="form-item position-relative password-form-reg">
              <label>Password</label>
              <input
                style={
                  secondaryData.error && secondaryData.errorMessage === 'PASSWORD_NOT_SECURE'
                    ? {
                        border: '1px',
                        borderColor: '#FB6F6F',
                        borderStyle: 'solid',
                      }
                    : {}
                }
                required
                placeholder={'Password (minimum 8 symbols)'}
                type={!showPassword ? 'password' : 'text'}
                name="password"
                onChange={fieldChangeHandler}
                value={formData.password}
              />
              <span
                className={`eye-icon-reg ${showPassword ? ' active' : ''}`}
                onClick={() => setShowPassword(!showPassword)}
              >
                {eyeIcon}
              </span>
            </div>
            <div className="form-item">
              <label>Referral code</label>
              <input
                // style={{ border: "none" }}
                placeholder={'Referral code (optional)'}
                type="text"
                name="referralCode"
                onChange={fieldChangeHandler}
                value={formData.referralCode}
                maxLength={255}
              />
            </div>
            {formData.promoCode && (
              <div className="form-item">
                <input
                  placeholder={'Code'}
                  type="text"
                  name="code"
                  onChange={fieldChangeHandler}
                  value={formData.promoCode}
                />
              </div>
            )}
            {passwordMessage && (
              <Message
                type="error"
                text={
                  'Your password should consist of at least 8 characters including at least 1 digit, 1 capital letter and at least 1 special character'
                }
              />
            )}
            {limitExceed && <Message type="error" text="Maximum allowed password length is 64 characters." />}
            <div className={secondaryData.errorMessage ? 'visible' : 'invisible'}>
              <Message
                type="error"
                text={<Translate name={secondaryData.errorMessage} /> || secondaryData.errorMessage}
              />
            </div>
          </div>
          <div className="terms">
            <div className="toggle-checkbox">
              <Checkbox checked={secondaryData.checked} click={checkHandler} />
              <span className="text">I agree to the&nbsp;</span>
              <span className="terms-text">
                <a
                  href="https://hexomatic.com/terms-of-use"
                  target="_blank"
                  aria-label={'https://hexomatic.com/terms-of-use'}
                >
                  Terms of Service
                </a>
              </span>{' '}
              <span style={{color: 'red'}}>*</span>
            </div>
          </div>
          <div className="submit-block">
            <button className="submit-btn" onClick={submitHandler}>
              {loading ? <FontAwesomeIcon icon={faSpinner} className="button-loading" /> : <Translate name="SIGN_UP" />}
            </button>
          </div>
          <div className="sign-in">
            <span className="text">
              <Translate name="ALREADY_MEMBER" />
              &nbsp;
            </span>
            <span className="sign-in-text" onClick={toLogin}>
              <Translate name="LOG_IN" />
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default Registration;
