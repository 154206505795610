import React, { FC } from "react";
// import checkIcon from '../../../asset/images/hexowatch/icons/checked.svg';
import { checkedIcon, checkIcon } from "../../images/svgIcons";
import "./styles.scss";

const Checkbox: FC<{
  checked: boolean;
  disabled?: boolean;
  click: (arg0: boolean) => void;
}> = ({ checked, disabled, click }) => {
  return (
    <div className="noselect">
      {checked ? (
        <div
          className={`custom-checkbox checked ${disabled ? "disabled" : ""}`}
          onClick={(e) => {
            if (!disabled) {
              e.preventDefault();
              e.stopPropagation();
              click(!checked);
            }
          }}
        >
          {disabled ? checkedIcon : checkIcon}
        </div>
      ) : (
        <div
          className={`custom-checkbox ${disabled ? "disabled" : ""}`}
          onClick={() => click(!checked)}
        ></div>
      )}
    </div>
  );
};

export default Checkbox;
