import React from 'react';
import {navigate} from 'gatsby';
import {getItem} from '../registration';
import {twofa} from '../../images/svgIcons';
import './styles.scss';
const handleChangeTwoFactor = async () => {
  if (window.location.host.includes('localhost')) {
    window.location.href = 'http://localhost:3000/profile/security';
  } else if (window.location.host.includes('37.186.119.181')) {
    window.location.href = 'http://37.186.119.181:3279/profile/security';
  } else {
    window.location.href = 'https://dash.hexomatic.com/profile/security';
  }
};
const redirects = () => {
  if (localStorage.getItem('checkout-data')) {
    navigate('/pricing/checkout');
  } else {
    if (window.location.host.includes('localhost')) {
      window.location.href = 'http://localhost:3000';
    } else if (window.location.host.includes('37.186.119.181')) {
      window.location.href = 'http://37.186.119.181:3279';
    } else {
      window.location.href = 'https://dash.hexomatic.com';
    }
  }
};
const ChangeToFactor = () => (
  <div className="text-center two-factor">
    <div className="d-flex mb-3 justify-content-center">{twofa}</div>
    <h4 className="text-center enable-text">Enable two-factor authentication</h4>

    <div className="submit-block">
      <button className="cancel-btn mr-2" onClick={redirects}>
        Close
      </button>
      <button className="submit-btn" onClick={handleChangeTwoFactor}>
        Get started
      </button>
    </div>
  </div>
);

export default ChangeToFactor;
